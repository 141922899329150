import React, { useState } from "react"
import styled from "styled-components"
import TiposImg from "../../../images/components/TiposImg.webp"
import useWindowSize from "../../../hooks/useWindowSize"
import "../../../css/typography.css"

const MainDiv = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5%;
    position: relative;
    margin-bottom: 10%;

    p {
        font-family: 'A Heavy';
        font-size: 20px;
        color: white;
    }
    img {
        width: 700px;
        position: absolute;
        right: 0;
        top: 40%;
    }
    .selected-p {
        color: #092247;
        font-family: 'A Regular';
    }
    .card-blue {
        width: 380px;
        border-radius: 6px;
        padding: 5px 0 5px 30px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        
    }
    .card-blue p {
        margin: 0;
    }
    >div:nth-child(1) {
        margin-right: 7%;
    }
    >div:nth-child(2) {
        display: flex;
        flex-direction: column;
    }
    @media only screen and (min-width: 1500px){
        >div:nth-child(1){
            width: 600px;
            margin-right: 0px;
        }
        >div:nth-child(2){
            width:900px;
        }
        .card-blue {
            width: 450px;
            height: 45px;
            border-width: 1px;
        }
        .card-blue p { 
            font-size: 25px;
        }
        .selected-p{
            font-size: 25px;
            width: 800px;
            margin-bottom: 100px;
        }
        img {
            width: 800px;
        }
    }
    @media only screen and (min-width: 1800px){
        >div:nth-child(1){
            width: 800px;
        }
        >div:nth-child(2){
            width:1000px;
        }
        .card-blue {
            width: 550px;
            height: 55px;
        }
        .card-blue p { 
            font-size: 30px;
        }
        .selected-p{
            font-size: 30px;
            width: 900px;
            margin-bottom: 200px;
        }
        img {
            width: 900px;
        }
    }
    @media only screen and (min-width: 2500px){
        >div:nth-child(1){
            width: 1000px;
        }
        >div:nth-child(2){
            width:1200px;
        }
        .card-blue {
            width: 850px;
            height:75px;
            border-radius:15px;
        }
        .card-blue p { 
            font-size: 40px;
        }
        .selected-p{
            font-size: 40px;
            width: 1200px;
            margin-bottom: 600px;
        }
        img {
            width: 1100px;
        }
    }
    @media only screen and (min-width: 3500px){
        >div:nth-child(1){
            width: 1600px;
        }
        >div:nth-child(2){
            width:1900px;
        }
        .card-blue {
            width: 1250px;
            height: 105px;
            border-radius:19px;
        }
        .card-blue p { 
            font-size: 50px;
        }
        .selected-p{
            font-size: 50px;
            width: 1700px;
            margin-bottom: 900px;
        }
        img {
            width: 1500px;
        }
    }
    @media only screen and (min-width: 4000px){
        >div:nth-child(1){
            width: 1800px;
        }
        >div:nth-child(2){
            width: 2200px;
        }
        .card-blue {
            width: 1350px;
            height: 155px;
            border-radius: 20px;
        }
        .card-blue p { 
            font-size: 60px;
        }
        .selected-p{
            font-size: 60px;
            width: 1700px;
            margin-bottom: 900px;
        }
        img {
            width: 1800px;
        }
    }
    @media only screen and (max-width: 1155px) {
        .card-blue {
            width: 300px;
        }
        img {
            width: 500px;
            top: 55%;
        }
    }
`

const MainDivMov = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    margin-bottom: 10%;

    p {
        font-family: 'A Heavy';
        font-size: 20px;
        color: white;
    }
    img {
        width: 70vw;
        margin-right: -30vw;
    }
    .selected-p {
        color: #092247;
        font-family: 'A Regular';
        width: 80vw;
    }
    .card-blue {
        width: 80vw;
        border-radius: 6px;
        padding: 5px 0 5px 10px;
        margin-bottom: 10px;
    }
    .card-blue p {
        margin: 0;
    }
    >div:nth-child(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media only screen and (max-width: 540px) {
        img {
            width: 110vw;
            margin-right: 0;
        }
    }

`

const CheckDiv = () => {
    const [selected, setSelected] = useState('supervisión')
    const windowSize = useWindowSize()

    return  windowSize > 950 ? (
        <MainDiv>
            <div>
                <div style={{ backgroundColor: selected === 'supervisión' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('supervisión')}
                className='card-blue'
                >
                    <p>Reporte final de supervisión</p>
                </div>
                <div style={{ backgroundColor: selected === 'sucursales' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('sucursales')}
                className='card-blue'
                >
                    <p>Reporte de visitas a sucursales</p>
                </div>
                <div style={{ backgroundColor: selected === 'evaluaciones' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('evaluaciones')}
                className='card-blue'
                >
                    <p>Reporte de evaluaciones</p>
                </div>
                <div style={{ backgroundColor: selected === 'desempeño' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('desempeño')}
                className='card-blue'
                >
                    <p>Reporte de desempeño</p>
                </div>
                <div style={{ backgroundColor: selected === 'promedios' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('promedios')}
                className='card-blue'
                >
                    <p>Gráfica de Matriz Promedios</p>
                </div>
                <div style={{ backgroundColor: selected === 'vitales' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('vitales')}
                className='card-blue'
                >
                    <p>Reporte de Signos Vitales</p>
                </div>
                <div style={{ backgroundColor: selected === 'todos' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('todos')}
                className='card-blue'
                >
                    <p>Reporte de To-Do’s</p>
                </div>
                <div style={{ backgroundColor: selected === 'tendencia' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('tendencia')}
                className='card-blue'
                >
                    <p>Reporte de Tendencia de To-Do’s</p>
                </div>
            </div>
            <div>
                <p className="selected-p">
                    {selected === 'supervisión' ? 'Documento con la información obtenida sobre la aplicación del Checklist en la sucursal, donde contiene la ubicación, el tiempo que se tardó en realizarlo, la persona que lo aplica, los campos que generaron To-Do’s y la ponderación general.' :
                    selected === 'sucursales' ? 'Consulta donde se pueden visualizar las visitas que se han realizado en un periodo de tiempo, contiene el número de visitas realizadas, las fechas en que se realizaron, el tiempo que tardaron en aplicarse y el nombre de las personas que las hicieron.' : 
                    selected === 'evaluaciones' ? 'Excel donde se pueden visualizar las calificaciones de las visitas realizadas en un mes en específico. En este reporte podemos identificar en que día y en que rango de calificación estuvo la visita' : 
                    selected === 'desempeño' ? 'Reporte que ayuda a visualizar la actividad del Supervisor y dar métrica sobre su avance con respecto a las actividades generadas.' : 
                    selected === 'promedios' ? 'Matriz que ayuda a realizar comparativa entre sucursales con los indicadores de Transacciones vs Tickets, con esto se pueden generar estrategias para saber sobre que indicador trabajar con cada cuadrante.' : 
                    selected === 'vitales' ? 'Reporte donde se visualiza en una tabla los indicadores: Tickets, Transa. Diarias y Art. X Ticket denominados Signos Vitales donde se pueden ordenar del mayor al menor valor. Se colorea en verde si el valor es mayor al mes anterior, rojo si es menor y amarillo si es el mismo.' : 
                    selected === 'todos' ? 'Reporte mediante el cual podemos visualizamos el listado de To-Do’s en sus diferentes estatus y asignado a las diferentes sucursales. Esto con el objetivo de dar seguimiento a las actividades abiertas y saber cómo han evolucionado mediante comentarios y fotografías.' :
                    selected === 'tendencia' ? 'Indicador que muestra los 5 To-Do’s que más incidieron en una sucursal o en la empresa en general.' :  
                    null}
                </p>
                <img src={TiposImg} alt="Tipos Img" />
            </div>
        </MainDiv>
    ) : (
        <MainDivMov>
            <div>
                <div style={{ backgroundColor: selected === 'supervisión' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('supervisión')}
                className='card-blue'
                >
                    <p>Reporte final de supervisión</p>
                </div>
                {selected === 'supervisión' ? (
                    <>
                        <p className="selected-p">Documento con la información obtenida sobre la aplicación del Checklist en la sucursal, donde contiene la ubicación, el tiempo que se tardó en realizarlo, la persona que lo aplica, los campos que generaron To-Do’s y la ponderación general.</p>
                        <img src={TiposImg} alt="Tipos Img" />
                    </>
                ) : null}
                <div style={{ backgroundColor: selected === 'sucursales' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('sucursales')}
                className='card-blue'
                >
                    <p>Reporte de visitas a sucursales</p>
                </div>
                {selected === 'sucursales' ? (
                    <>
                        <p className="selected-p">Consulta donde se pueden visualizar las visitas que se han realizado en un periodo de tiempo, contiene el número de visitas realizadas, las fechas en que se realizaron, el tiempo que tardaron en aplicarse y el nombre de las personas que las hicieron.</p>
                        <img src={TiposImg} alt="Tipos Img" />
                    </>
                ) : null}
                <div style={{ backgroundColor: selected === 'evaluaciones' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('evaluaciones')}
                className='card-blue'
                >
                    <p>Reporte de evaluaciones</p>
                </div>
                {selected === 'evaluaciones' ? (
                    <>
                        <p className="selected-p">Excel donde se pueden visualizar las calificaciones de las visitas realizadas en un mes en específico. En este reporte podemos identificar en que día y en que rango de calificación estuvo la visita</p>
                        <img src={TiposImg} alt="Tipos Img" />
                    </>
                ) : null}
                <div style={{ backgroundColor: selected === 'desempeño' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('desempeño')}
                className='card-blue'
                >
                    <p>Reporte de desempeño</p>
                </div>
                {selected === 'desempeño' ? (
                    <>
                        <p className="selected-p">Reporte que ayuda a visualizar la actividad del Supervisor y dar métrica sobre su avance con respecto a las actividades generadas.</p>
                        <img src={TiposImg} alt="Tipos Img" />
                    </>
                ) : null}
                <div style={{ backgroundColor: selected === 'promedios' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('promedios')}
                className='card-blue'
                >
                    <p>Gráfica de Matriz Promedios</p>
                </div>
                {selected === 'promedios' ? (
                    <>
                        <p className="selected-p">Matriz que ayuda a realizar comparativa entre sucursales con los indicadores de Transacciones vs Tickets, con esto se pueden generar estrategias para saber sobre que indicador trabajar con cada cuadrante.</p>
                        <img src={TiposImg} alt="Tipos Img" />
                    </>
                ) : null}
                <div style={{ backgroundColor: selected === 'vitales' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('vitales')}
                className='card-blue'
                >
                    <p>Reporte de Signos Vitales</p>
                </div>
                {selected === 'vitales' ? (
                    <>
                        <p className="selected-p">Reporte donde se visualiza en una tabla los indicadores: Tickets, Transa. Diarias y Art. X Ticket denominados Signos Vitales donde se pueden ordenar del mayor al menor valor. Se colorea en verde si el valor es mayor al mes anterior, rojo si es menor y amarillo si es el mismo.</p>
                        <img src={TiposImg} alt="Tipos Img" />
                    </>
                ) : null}
                <div style={{ backgroundColor: selected === 'todos' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('todos')}
                className='card-blue'
                >
                    <p>Reporte de To-Do’s</p>
                </div>
                {selected === 'todos' ? (
                    <>
                        <p className="selected-p">Reporte mediante el cual podemos visualizamos el listado de To-Do’s en sus diferentes estatus y asignado a las diferentes sucursales. Esto con el objetivo de dar seguimiento a las actividades abiertas y saber cómo han evolucionado mediante comentarios y fotografías.</p>
                        <img src={TiposImg} alt="Tipos Img" />
                    </>
                ) : null}
                <div style={{ backgroundColor: selected === 'tendencia' ? '#49BAEA' : '#092247' }}
                onClick={() => setSelected('tendencia')}
                className='card-blue'
                >
                    <p>Reporte de Tendencia de To-Do’s</p>
                </div>
                {selected === 'tendencia' ? (
                    <>
                        <p className="selected-p">Indicador que muestra los 5 To-Do’s que más incidieron en una sucursal o en la empresa en general.</p>
                        <img src={TiposImg} alt="Tipos Img" />
                    </>
                ) : null}
            </div>
        </MainDivMov>
    )  
}

export default CheckDiv
