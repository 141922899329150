import React from "react"
import styled from "styled-components"
import Check from "../../../images/components/Check.webp"
import "../../../css/typography.css"

const MainDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    p {
        font-family: 'A Regular';
        color: #092247;
        font-size: 20px;
        line-height: 25px;
        margin: 0;
    }
    @media only screen and (min-width: 1200px){
        img {
            width: 25px;
            height: 25px;
        }
        p {
            font-size: 23px;
            width: 400px;
        }
       
    }
    @media only screen and (min-width: 1500px){
        img {
            width: 30px;
            height: 30px;
        }
        p {
            font-size: 30px;
            width: 800px;
            line-height: 35px;
        }
    }
    @media only screen and (min-width: 1800px){
        margin-bottom: 30px;
        img {
            width: 35px;
            height: 35px;
        }
        p {
            font-size: 35px;
            width: 1000px;
        }
    }
    @media only screen and (min-width: 2500px){
        img {
            width: 40px;
            height: 40px;
        }
        p {
            font-size: 45px;
            width: 1300px;
            line-height: 50px;
        }
    }
    @media only screen and (min-width: 3500px){
        img {
            width: 50px;
            height: 50px;
        }
        p {
            font-size: 60px;
            width: 1900px;
            line-height: 80px;
        }
    }
    @media only screen and (min-width: 4000px){
        img {
            width: 55px;
            height: 55px;
        }
        p {
            font-size: 70px;
            width: 2000px;
            line-height: 90px;
        }
    }
  
  
`

const CheckDiv = (props) => {

    return  (
        <MainDiv>
            <img src={Check} alt="Check" />
            <p>{props.text}</p>
        </MainDiv>
    )
        
}

export default CheckDiv
