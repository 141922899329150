import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import Background from "../images/home/Background.webp"
import BackgroundMov from "../images/home/BackgroundMov.webp"
import useWindowSize from "../hooks/useWindowSize"
import CTA from "../components/buttons/cta"
import FirstImage from "../images/sistema/FirstSection.webp"
import VisitasImg from "../images/sistema/VisitasImg.webp"
import Check from "../components/sistema/check"
import CheckImg from "../images/sistema/CheckImg.webp"
import SucursalesImg from "../images/sistema/SucursalesImg.webp"
import GraficosImg from "../images/sistema/GraficosImg.webp"
import ReportesImg from "../images/sistema/ReportesImg.webp"
import Reportes from "../components/sistema/reportes"
import "../css/typography.css"

const FirstSection =styled.div`
    color: white;
    background-image: url(${Background});
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-bottom: 3%;

    h1 {
        font-family: 'A Black';
        font-size: 32px;
        margin-bottom: 0;
    }
    p {
        font-family: 'A Regular';
        font-size: 20px;
        margin-bottom: 0;
        line-height: 25px;
    }
    img {
        width: 600px;
        margin-top: 10px;
    }
    >div:nth-child(1){
        display: flex;
        flex-direction: column;
    }
    >div:nth-child(1)>div{
        display: flex;
        margin-top: 5%;
        justify-content: space-between;
        width: 400px;
    }

    @media only screen and (min-width: 1200px) {  
        padding-bottom: 5%;

        h1 {
        font-size: 45px;
        }
        p {
        width: 460px;
        font-size: 23px;
        margin-bottom: 15px;
        }
    }
    @media only screen and (min-width: 1500px) {  
        padding-bottom: 5%;

        h1 {
        font-size: 50px;
        }
        p {
        width: 760px;
        font-size: 25px;
        }
        >div:nth-child(1){
        width:700px;
        }
    }
    @media only screen and (min-width: 1800px) {  
        h1 {
        font-size: 60px;
        margin-top: 80px;
        }
        p {
        width: 760px;
        font-size: 30px;
        }
        img {
        width: 900px;
        } 
        >div:nth-child(1)>div{
        width:470px;
        }
    }
    @media only screen and (min-width: 2500px) {  
        h1 {
        font-size: 70px;
        margin-top: 80px;
        }
        p {
        width: 1000px;
        font-size: 45px;
        line-height: 50px;
        }
        img {
        width: 1200px;
        } 
        >div:nth-child(1){
        width: 1000px;
        }
        >div:nth-child(1)>div{
        width:620px;
        }
    }
    @media only screen and (min-width: 3500px) {  
        h1 {
        font-size: 100px;
        margin-top: 80px;
        }
        p {
        width: 1300px;
        font-size: 55px;
        line-height: 60px;
        }
        img {
        width: 1500px;
        } 
        >div:nth-child(1){
        width: 1500px;
        }
        >div:nth-child(1)>div{
        width:820px;
        }
    }
    @media only screen and (min-width: 4000px) {  
        h1 {
        font-size: 130px;
        margin-top: 80px;
        }
        p {
        width: 1700px;
        font-size: 70px;
        line-height: 70px;
        }
        img {
        width: 2000px;
        } 
        >div:nth-child(1){
        width: 2000px;
        }
        >div:nth-child(1)>div{
        width: 1050px;
        }
    }
    @media only screen and (max-width: 1115px) {  
        padding-bottom: 5%;

        img {
            width: 500px;
        }
    }
    @media only screen and (max-width: 1015px) {  
        padding-bottom: 15%;

        img {
            width: 400px;
        }
    }
    @media only screen and (max-width: 820px) {  
        flex-direction: column;
        padding: 5%;

        img {
            width: 70vw;
            margin-right: -30vw;
        }
        >div:nth-child(1){
            align-items: center;    
        }
        h1 {
            align-self: flex-start;
        }
    }
    @media only screen and (max-width: 540px) {  
        background-image: url(${BackgroundMov});
        padding: 10%;

        h1 {
            font-size: 7vw;
        }
        p {
            font-size: 5.5vw;
            line-height: 30px;
        }
        img {
            width: 130vw;
            margin-left: -16vw;
        }
        >div:nth-child(1)>div{
            width: 90vw;
            }
        }
`

const VisitasDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #092247;
    padding: 5% 10% 5% 10%;

    h2 {
        font-family: 'A Black';
        margin-bottom: 10px;
        font-size: 25px;
    }
    p {
        font-family: 'A Regular';
        margin-top: 0;
        font-size: 20px;
    }
    img {
        width: 450px;
        height: 100%;
    }
    >div {
        padding: 0 2% 0 8%;
    }

    @media only screen and (min-width: 1200px) {
    img {
      width: 450px;
      margin-left:-30px;
    }
    h2 {
      font-size: 35px;
    }
    p {
      font-size: 23px;
      width: 600px;
    }
    >div {
        padding: 0 2% 0 3%;
    }
  }
  @media only screen and (min-width: 1500px) {
    img {
      width: 500px;
      margin-left:-50px;
    }
    h2 {
      font-size: 45px;
    }
    p {
      font-size: 30px;
      width: 800px;
    }
  }
  @media only screen and (min-width: 1800px) {
    img {
      width: 600px;
      margin-left:-50px;
    }
    h2 {
      font-size: 55px;
    }
    p {
      font-size: 35px;
      width: 1000px;
    }
  }
  @media only screen and (min-width: 2500px) {
    img {
      width: 800px;
      margin-left:-50px;
    }
    h2 {
      font-size: 65px;
    }
    p {
      font-size: 45px;
      width: 1300px;
    }
  }
  @media only screen and (min-width: 3500px) {
    img {
      width: 1200px;
      margin-left:-200px;
    }
    h2 {
      font-size: 95px;
    }
    p {
      font-size: 60px;
      width: 1900px;
    }
  }
  @media only screen and (min-width: 4000px) {
    img {
      width: 1800px;
    }
    h2 {
      font-size: 125px;
    }
    p {
      font-size: 70px;
      width: 2000px;
    }
  }
    @media only screen and (max-width: 950px) {
        flex-direction: column;

        img {
            width: 80vw;
        }
        >div {
        padding: 5%;
    }
    }
`

const ChecklistDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e6fbff;
    color: #092247;
    padding: 6%;

    h2 {
        font-family: 'A Oblique';
        margin-bottom: 10px;
        font-size: 25px;
    }
    p {
        font-family: 'A Regular';
        margin-top: 0;
        font-size: 20px;
    }
    >div {
        display: flex;
        flex-direction: column;
        margin-right: 5%;
    }
    >img {
        width: 450px;
        height: 100%;
    }
    @media only screen and (min-width: 1200px) {
    >img {
      width: 450px;
      margin-left:-30px;
    }
    h2 {
      font-size: 35px;
    }
    p {
      font-size: 23px;
      width: 600px;
    }
  }
  @media only screen and (min-width: 1500px) {
    >img {
      width: 500px;
      margin-left:-50px;
    }
    h2 {
      font-size: 45px;
    }
    p {
      font-size: 30px;
      width: 800px;
      line-height: 35px;
    }
  }
  @media only screen and (min-width: 1800px) {
    >img {
      width: 600px;
      margin-left:-50px;
    }
    h2 {
      font-size: 55px;
    }
    p {
      font-size: 35px;
      width: 1000px;
    }
  }
  @media only screen and (min-width: 2500px) {
    >img {
      width: 800px;
      margin-left:-50px;
    }
    h2 {
      font-size: 65px;
    }
    p {
      font-size: 45px;
      width: 1300px;
      line-height: 50px;
    }
  }
  @media only screen and (min-width: 3500px) {
    >img {
      width: 1200px;
      margin-left:-200px;
    }
    h2 {
      font-size: 95px;
    }
    p {
      font-size: 60px;
      width: 1900px;
      line-height: 80px;
    }
  }
  @media only screen and (min-width: 4000px) {
    >img {
      width: 1800px;
    }
    h2 {
      font-size: 125px;
    }
    p {
      font-size: 70px;
      width: 2000px;
      line-height: 90px;
    }
  }
    @media only screen and (max-width: 950px) {
        flex-direction: column;

        >img {
            width: 80vw;
        }
        >div {
        padding: 5%;
        margin-right: 0;
    }
    }
`

const SucursalesDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #092247;
    padding: 6%;

    h2 {
        font-family: 'A Black';
        margin-bottom: 10px;
        font-size: 25px;
    }
    p {
        font-family: 'A Regular';
        margin-top: 0;
        font-size: 20px;
    }
    >div {
        display: flex;
        flex-direction: column;
        margin-left: 5%;
    }
    >img {
        width: 450px;
        height: 100%;
    }
    @media only screen and (min-width: 1200px) {
    >img {
      width: 450px;
      margin-left:-30px;
    }
    h2 {
      font-size: 35px;
    }
    p {
      font-size: 23px;
      width: 600px;
    }
  }
  @media only screen and (min-width: 1500px) {
    >img {
      width: 500px;
      margin-left:-50px;
    }
    h2 {
      font-size: 45px;
    }
    p {
      font-size: 30px;
      width: 800px;
      line-height: 35px;
    }
  }
  @media only screen and (min-width: 1800px) {
    >img {
      width: 600px;
      margin-left:-50px;
    }
    h2 {
      font-size: 55px;
    }
    p {
      font-size: 35px;
      width: 1000px;
    }
  }
  @media only screen and (min-width: 2500px) {
    >img {
      width: 800px;
      margin-left:-50px;
    }
    h2 {
      font-size: 65px;
    }
    p {
      font-size: 45px;
      width: 1300px;
      line-height: 50px;
    }
  }
  @media only screen and (min-width: 3500px) {
    >img {
      width: 1200px;
      margin-left:-200px;
    }
    h2 {
      font-size: 95px;
    }
    p {
      font-size: 60px;
      width: 1900px;
      line-height: 80px;
    }
  }
  @media only screen and (min-width: 4000px) {
    >img {
      width: 1800px;
    }
    h2 {
      font-size: 125px;
    }
    p {
      font-size: 70px;
      width: 2000px;
      line-height: 90px;
    }
  }
    @media only screen and (max-width: 950px) {
        flex-direction: column;

        >img {
            width: 80vw;
        }
        >div {
        padding: 5%;
        margin-left: 0;
    }
    }
`

const ReportesDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #092247;
    background-color: #e6fbff;
    padding: 6%;

    h2 {
        font-family: 'A Black';
        margin-bottom: 10px;
        font-size: 25px;
    }
    p {
        font-family: 'A Regular';
        margin-top: 0;
        font-size: 20px;
    }
    >div {
        display: flex;
        flex-direction: column;
        margin-right: 5%;
    }
    >img {
        width: 600px;
        height: 100%;
        margin-right: -90px;
    }
    @media only screen and (min-width: 1200px) {
    img {
      width: 450px;
      
    }
    h2 {
      font-size: 35px;
    }
    p {
      font-size: 23px;
      width: 600px;
    }
    >div {
        padding: 0 2% 0 3%;
    }
  }
  @media only screen and (min-width: 1500px) {
    img {
      width: 500px;
     
    }
    h2 {
      font-size: 45px;
    }
    p {
      font-size: 30px;
      width: 800px;
    }
  }
  @media only screen and (min-width: 1800px) {
    img {
      width: 600px;
     
    }
    h2 {
      font-size: 55px;
    }
    p {
      font-size: 35px;
      width: 1000px;
    }
  }
  @media only screen and (min-width: 2500px) {
    img {
      width: 800px;
   
    }
    h2 {
      font-size: 65px;
    }
    p {
      font-size: 45px;
      width: 1300px;
    }
  }
  @media only screen and (min-width: 3500px) {
    img {
      width: 1200px;
    }
    h2 {
      font-size: 95px;
    }
    p {
      font-size: 60px;
      width: 1900px;
    }
  }
  @media only screen and (min-width: 4000px) {
    img {
      width: 1800px;
    }
    h2 {
      font-size: 125px;
    }
    p {
      font-size: 70px;
      width: 2000px;
    }
  }
    @media only screen and (max-width: 950px) {
        flex-direction: column;

        >img {
            width: 80vw;
            margin-right: -22vw;
        }
        >div {
            padding: 5%;
            margin-right: 0;
        }
    }
`

const GraficosDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #092247;
    background-color: #e6fbff;
    padding: 6%;

    h2 {
        font-family: 'A Black';
        margin-bottom: 10px;
        font-size: 25px;
    }
    p {
        font-family: 'A Regular';
        margin-top: 0;
        font-size: 20px;
    }
    >div {
        display: flex;
        flex-direction: column;
        margin-right: 5%;
    }
    >img {
        width: 400px;
        height: 100%;
    }
    @media only screen and (min-width: 1200px) {
    img {
      width: 250px;
      
    }
    h2 {
      font-size: 35px;
    }
    p {
      font-size: 23px;
      width: 600px;
    }
    >div {
        padding: 0 2% 0 3%;
    }
  }
  @media only screen and (min-width: 1500px) {
    img {
      width: 350px;
     
    }
    h2 {
      font-size: 45px;
    }
    p {
      font-size: 30px;
      width: 800px;
    }
  }
  @media only screen and (min-width: 1800px) {
    img {
      width: 450px;
     
    }
    h2 {
      font-size: 55px;
    }
    p {
      font-size: 35px;
      width: 1000px;
    }
  }
  @media only screen and (min-width: 2500px) {
    img {
      width: 600px;
   
    }
    h2 {
      font-size: 65px;
    }
    p {
      font-size: 45px;
      width: 1300px;
    }
  }
  @media only screen and (min-width: 3500px) {
    img {
      width: 1000px;
    }
    h2 {
      font-size: 95px;
    }
    p {
      font-size: 60px;
      width: 1900px;
    }
  }
  @media only screen and (min-width: 4000px) {
    img {
      width: 1500px;
    }
    h2 {
      font-size: 125px;
    }
    p {
      font-size: 70px;
      width: 2000px;
    }
  }
    @media only screen and (max-width: 950px) {
        flex-direction: column;

        >img {
            width: 60vw;
        }
        >div {
            padding: 5%;
            margin-right: 0;
        }
    }
`

const ReportesHead = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #092247;
    margin-top: 5%;

    h2 {
        font-family: 'A Black';
        margin-bottom: 10px;
        font-size: 25px;
    }
    p {
        font-family: 'A Regular';
        margin-top: 0;
        font-size: 20px;
        text-align: center;
        width: 600px;
    }
    div {
        border-bottom: 1px solid gray;
        width: 80px;
        height: 1px;
    }
    @media only screen and (min-width: 1200px) {
    h2 {
      font-size: 35px;
    }
    p {
      font-size: 23px;
      width: 600px;
    }
  }
  @media only screen and (min-width: 1500px) {
    h2 {
      font-size: 45px;
    }
    p {
      font-size: 30px;
      width: 800px;
      line-height: 35px;
    }
  }
  @media only screen and (min-width: 1800px) {
    h2 {
      font-size: 55px;
    }
    p {
      font-size: 35px;
      width: 1000px;
    }
  }
  @media only screen and (min-width: 2500px) {
    h2 {
      font-size: 65px;
    }
    p {
      font-size: 45px;
      width: 1300px;
      line-height: 50px;
    }
  }
  @media only screen and (min-width: 3500px) {
    h2 {
      font-size: 95px;
    }
    p {
      font-size: 60px;
      width: 1900px;
      line-height: 80px;
    }
  }
  @media only screen and (min-width: 4000px) {
    h2 {
      font-size: 125px;
    }
    p {
      font-size: 70px;
      width: 2000px;
      line-height: 90px;
    }
  }
    @media only screen and (max-width: 570px) {
        p {
            width: 85vw;
        }
    }
`


const NuestroSistema = () => {
    const [hasMounted, setHasMounted] = useState(false) 
    const windowSize = useWindowSize()

    useEffect(() => {
        setHasMounted(true)
    }, [])

return hasMounted ? (        
    <Layout>
        <FirstSection>
            <div>
                <h1>CONOCE VENTUM</h1>
                <p>Ventum surgió como una idea para optimizar los procesos de supervisión en negocios propios.</p>
                <p>Tal fue su éxito que se convirtió en una herramienta que ahora se implementa en grandes organizaciones.</p>
                <div>
                    <CTA text='Contáctanos' link='/contacto' />
                </div>
            </div>
            <div>
                <img src={FirstImage} alt="First Image" />
            </div>
        </FirstSection>
        <VisitasDiv>
            {windowSize > 950 ? (
                <img src={VisitasImg} alt="Visitas img" />
            ) : (null)}
            <div>
                <h2>VISITAS</h2>
                <p>Registra en el sistema las sucursales con las que cuentas y determina los tipos de visitas que hará el supervisor.</p>
                <p> Lo mejor de todo es que sus visitas son geolocalizables en tiempo real. De esta forma, tienes un mejor control del personal y su tiempo en cada sucursal.</p>
            </div>
            {windowSize < 950 ? (
                <img src={VisitasImg} alt="Visitas img" />
            ) : (null)}
        </VisitasDiv>
        <ChecklistDiv>
            <div>
                <h2>CHECKLIST</h2>
                <p>Personaliza tus checklist para que el supervisor corrobore que todo esté en orden durante su visita. Por ejemplo:</p>
                <Check text='Los empleados portan el uniforme adecuadamente.' />
                <Check text='El almacén está organizado por ítem.' />
                <Check text='Se respetan las medidas de higiene y seguridad.' />
                <p>¿La sucursal incumplió en algo?</p>
                <p> El supervisor puede marcar el problema, agregar evidencias fotográficas, comentar, proponer una solución y poner una calificación. Podrás tener control total sobre la calidad de tus productos, instalaciones y personal.</p>
                <p>¡Ventum es la mejor herramienta de supervisión en una empresa!</p>
            </div>
            <img src={CheckImg} alt="checklist" />
        </ChecklistDiv>
        <SucursalesDiv>
            {windowSize > 950 ? (
                <img src={SucursalesImg} alt="Sucursales img" />
            ) : (null)}
            <div>
                <h2>SUCURSALES</h2>
                <p>Mejora el rendimiento y la eficacia de cada sucursal con las herramientas que te ofrece Ventum.</p>
                <p> A partir de la recolección de datos de los checklist, el sistema:</p>
                <Check text='Genera estadísticas del funcionamiento y las ventas de la sucursal.' />
                <Check text='Determina las tareas necesarias a realizar y produce alertas para asegurar que se cumplan.' />
                <Check text='Envía correos electrónicos automáticos a las personas encargadas de resolver las tareas.' />
                <Check text='Identifica las FODA (fortalezas, oportunidades, debilidades y amenazas) para facilitar la planeación de estrategias.' />
                <Check text=' Almacena información de los empleados para mejorar la supervisión del personal.' />
                <Check text=' Genera un promedio del ticket anual para mantener un control de ventas e identificar si se alcanzaron los objetivos.' />
            </div>
            {windowSize < 950 ? (
                <img src={SucursalesImg} alt="Sucursales img" />
            ) : (null)}        
        </SucursalesDiv>
        <ReportesDiv>
            <div>
                <h2>REPORTES</h2>
                <p>La información alojada en el sistema se utiliza para generar reportes automáticamente.</p>
                <p> Puedes compartir los reportes por correo electrónico sin salir de la plataforma, descargarlos como PDF o exportarlos a una hoja de cálculo.</p>
            </div>
            <img src={ReportesImg} alt="Reportes Img" />
        </ReportesDiv>
        <ReportesHead>
            <h2>TIPOS DE REPORTES</h2>
            <p>Reportes desmenuzados de fácil entendimiento para tomar decisiones hacia el crecimiento de la organización</p>
            <div></div>
        </ReportesHead>
        <Reportes />
        <GraficosDiv>
            <div>
                <h2>GRÁFICOS</h2>
                <p>En Ventum sabemos que la organización lo es todo. Por eso, el sistema genera gráficos de todos los reportes. Así podrás analizar la información visual con mayor eficacia y mejorar el rendimiento y los procesos de las sucursales.</p>
                <p>¡Ventum se encarga de los datos, tú tomas las decisiones!</p>
            </div>
            <img src={GraficosImg} alt="Graficos img" />
        </GraficosDiv>
    </Layout>
    ) : (
    null
    )
}

export default NuestroSistema
