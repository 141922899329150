import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import "../../../css/typography.css"

const Button = styled.div`
    background-color: #49BAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 10px;
    padding: 20px;
    border-radius: 9px;

    a {
        text-decoration: none;
        font-family: 'A Heavy';
        color: #FFFFFF;
        font-size: 20px;
        text-align: center;
    }
    @media only screen and (min-width: 1200px) {
        
        height:20px;

        a {
            font-size: 20px;
        }
    }
    @media only screen and (min-width: 1500px) {
        
        height: 30px;
        width: 150px;

        a {
            font-size: 25px;
        }
    }
    @media only screen and (min-width: 1800px) {
        
        height: 40px;
        width: 180px;

        a {
            font-size: 30px;
        }
    }
    @media only screen and (min-width: 2500px) {
        
        height: 60px;
        width: 240px;

        a {
            font-size: 40px;
        }
    }
    @media only screen and (min-width: 3500px) {
        
        height: 80px;
        width: 340px;

        a {
            font-size: 55px;
        }
    }
    @media only screen and (min-width: 4000px) {
        
        height: 120px;
        width: 400px;

        a {
            font-size: 65px;
        }
    }
    @media only screen and (max-width: 910px) {
        a {
            font-size: 15px;
        }
    }
    @media only screen and (max-width: 540px) {
        width: 30vw;

        a {
            font-size: 4.5vw;
        }
    }
`

const AppButton = (props) => {

    return  (
        <Button>
            <Link to={props.link}>{props.text}</Link>
        </Button>
    )
        
}

export default AppButton
